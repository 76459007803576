import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import { HeaderDiv, DescrDiv, InfoContainer, FlexContainer, OnelinerDiv } from '../styles/div';

export default function About(props) {
    useEffect(() => {
        props.click('about');
      }, [])
    return (<div style={{textAlign: "left", overflowY: "scroll", padding: "2rem",  marginTop: "4rem"}}>
{/* 
    <div style={{padding: "1rem"}}>
    <DescrDiv>Contact us to know more: <a href="mailto:info@cogitarelabs.com?subject=<your query>&body=Hi Team">info@cogitarelabs.com</a></DescrDiv>
    </div> */}


    <InfoContainer>
    <FlexContainer>

        <div style={{flex: 1}}>
        <HeaderDiv><b>Innovating for Digital Immortality</b></HeaderDiv>
        {/* <OnelinerDiv></OnelinerDiv> */}
        <DescrDiv>
        <ol>Welcome to Cogitare Labs, where the future is not just imagined, but crafted with care, responsibility, and innovation.</ol>
        </DescrDiv>

        </div>
        </FlexContainer>
    </InfoContainer>

    <InfoContainer>
    <HeaderDiv><b>Our Vision: Digital Immortality for Humans</b></HeaderDiv>
    {/* <OnelinerDiv><i>"Metaverse built by communities coming from different walks of life"</i></OnelinerDiv> */}
    <DescrDiv>
    <ol>In a world where technology is evolving at a rapid pace, we are presented with an unprecedented opportunity to enhance human life. With the advancement of technologies such as Artificial Intelligence, Blockchain, Mixed Reality, Robotics, Brain-Computer Interfaces, and Neural Technology, we foresee the potential to achieve infinite digital existence or digital immortality for humans around this mid-century. </ol>
    <ol><b>Our vision is centered on realizing this digital immortality for humanity by creating digital beings that can think, learn, and interact in ways that are not just smart, but also empathetic and responsible.</b></ol>
{/* <ol><h2>Our new mission is to create active communities which can help us to create such platform.</h2></ol> 
<ol>Such thriving communities will be backed by different mobile and VR applications.</ol>
<ol>This could revolutionise how people communicate, opening up new possibilities for collaboration and connection between people from all walks of life.</ol> 
<ol>With the help of such applications, we intend to create a healthy ecosystem for any group or individuals.</ol> */}
    </DescrDiv>
    </InfoContainer>

    <InfoContainer>
    <HeaderDiv><b>Our Mission: Create Safe and Responsible Digital Beings</b></HeaderDiv>
    <DescrDiv>
        <ol>At the heart of our mission lies a commitment to creating advanced digital beings, like our very own Liana Arc, who represent the pinnacle of safe, ethical, and transformative artificial intelligence. </ol>
    </DescrDiv>
    </InfoContainer>

    <InfoContainer>
    <HeaderDiv><b>Safety and Responsibility: Our Core Principles</b></HeaderDiv>
    <DescrDiv>
        <ol>At Cogitare Labs, we don't just build technology; we nurture trust. Safety and ethical responsibility are the cornerstones of our creations. Every digital being we develop, including Liana Arc, is a testament to our rigorous standards for safety, privacy, and ethical AI. We are committed to advancing AI in a way that respects human values and promotes a positive societal impact.</ol>
    </DescrDiv>
    </InfoContainer>

    <InfoContainer>
    <HeaderDiv><b>Liana Arc: A Beacon of Our Innovation</b></HeaderDiv>
    <DescrDiv>
        <ol>Liana Arc is not just a digital being; she is a symbol of what AI can achieve when it's built with a purpose. Her existence is a harmonious blend of human-like intuition and advanced technological capabilities. Liana embodies our belief that digital beings can enrich our lives, offering new dimensions of interaction, learning, and growth.</ol>
    </DescrDiv>
    </InfoContainer>

    <InfoContainer>
    <HeaderDiv><b>Collaborative Future: Shaping Tomorrow Together</b></HeaderDiv>
    <DescrDiv>
        <ol>Our journey at Cogitare Labs is not a solitary one. We believe in collaboration – with experts, communities, and individuals like you. Together, we can explore the vast potential of AI and shape a future where digital beings and humans coexist, learn from each other, and build a better world.</ol>    
    </DescrDiv>
    </InfoContainer>

    <InfoContainer>
    <HeaderDiv><b>Join Us in Our Quest</b></HeaderDiv>
    <DescrDiv>
        <ol>As we continue to push the boundaries of what's possible, we invite you to join us. Whether you're a technology enthusiast, a curious observer, or someone passionate about the future of AI, there's a place for you in the Cogitare Labs story. Let's embark on this exciting journey together and unlock the limitless possibilities of a digital tomorrow.
                    </ol>    
    </DescrDiv>
    </InfoContainer>

    <InfoContainer>
    <HeaderDiv><b>Cogitare Labs: Digital Immortality for Humans</b></HeaderDiv>
    </InfoContainer>

       <div style={{padding: "1rem"}}>
    <DescrDiv>Please get in touch at <a style={{textDecoration: "underline"}} href="mailto:info@cogitarelabs.com?subject=Apply&body=Hi Team">info@cogitarelabs.com</a></DescrDiv>
    </div>

</div>)
}
