
import React, {useState, useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from "react-router-dom";
import Home from './components/home';
import About from './components/about';
import styled from 'styled-components';
import Career from './components/career';

const AppDiv = styled.div`
  color: white;
`
const HeaderDiv = styled.div`
  font-size: 24px;
  margin-bottom: 1rem;
`
const TopBar = styled.div`
background-color: rgba(0,0,0,0.8);
position: fixed;
top:0px;
width: 100%;
color: black;
padding: 0.5rem;
z-index: 999;
text-align: left;
`

const FlexTopBar = styled.div`
display: flex;
justify-content: space-between;
`
const FlexTopContainer = styled.div`
display: flex;
align-items: center;
div {
  margin-right: 0.5rem;
}
`

const LinkContainer = styled.div`
justify-content: center;
display: flex;
align-items: center;
a {
 cursor: pointer;
 display: flex;
 align-items: center;
 margin-right: 1rem;
}
span {
  color: white;
  font-size: 14px;
}
`

function App() {
  const [windowDimensions, setWindowDimensions] = useState();
  const [page, setPage] = useState("home");
  
  const click = (data) => {
    setPage(data);
  }

  useEffect(() => {

    function getWindowDimensions() {
      const { innerWidth: width, innerHeight: height } = window;
      return {
        width,
        height
      };
  }

  
  setWindowDimensions(getWindowDimensions());
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);
  return (
    <AppDiv>
            <Router>
      <HeaderDiv>
      <TopBar style={{backgroundColor: page === 'home'? "rgba(0,0,0,0)" :"rgba(0,0,0,0.8)"  }}>
      <FlexTopBar>
      <FlexTopContainer >
          <div style={{cursor: "pointer"}}>
            <span onClick={() => setPage('home')}>
          <img src='/images/logo_small.png'  width="40px" height="40px"/>
          </span>
          </div>
          {
            windowDimensions?.width>=600 &&
            <span onClick={() => setPage('home')}>
            <img src='/images/cogilogo.png'  style={{ cursor: "pointer"}} width="150px" height="30px"/>
            </span>
          }
       
       </FlexTopContainer>
        <LinkContainer>
            <span style={{marginRight:"1rem", cursor: 'pointer'}} onClick={() => setPage('about')}>About us</span>
       
          <a href="https://discord.gg/cy5ug5A4kX" target={"_blank"} rel="noreferrer">
              <img src="/images/discord.png" width="20px" height="16px" />
          </a>

          <a href="https://www.instagram.com/cogitarelabs/" target={"_blank"} rel="noreferrer">
              <img src="/images/instagram.png" width="20px" height="16px" />
          </a>

          <a href="https://twitter.com/CogitareLabs" target={"_blank"} rel="noreferrer">
              <img src="/images/twitter.png" width="20px" height="16px" />
          </a>
          <a href="https://www.youtube.com/@cogitarelabs828" target={"_blank"} rel="noreferrer">
              <img src="/images/youtube.png" width="20px" height="16px" />
          </a>
          <a href="https://opensea.io/cogitarelabs" target={"_blank"} rel="noreferrer">
              <img src="/images/opensea.png" width="20px" height="20px" />
          </a>
          </LinkContainer>
        </FlexTopBar>
        </TopBar>
      
        </HeaderDiv>

      <div>



        {
          page === 'home' &&
          <Home click={click}/>
        }
        {
          page === 'about' &&
          <About click={click}/>
        }
        {
          page === 'career' &&
          <Career click={click}/>
        }
              {/* <Routes> */}
        {/* <section id="home">
          <Home click={click}/>
        </section>
        <section id="about">
          <About click={click}/>
        </section>
        <section id="career">
          <Career click={click}/>
        </section> */}
    {/* <Route exact path="/" element={<Home click={click}/>} />
    <Route path="/about" element={<About click={click}/>} />
    <Route path="/career" element={<Career click={click}/>} />
    <Route path="*" element={<Navigate replace to="/" />} />
  </Routes> */}
      </div>

          </Router>
    </AppDiv>
  );
}

export default App;
