import React, { useEffect } from 'react';
import styled from 'styled-components';
import VideoLooper from 'react-video-looper';

const BgImgContainer = styled.div`
position: relative;
height: 100vh;
width: 100vw;
overflow: hidden;
z-index: 1;
display: flex;
align-items: center;
@media (max-width: 600px) {
  height:650px !important;
}
`

const ImgDiv = styled.div`
width: 100%;
height: 100%;
position: relative;
display: flex;
align-items: center;
justify-content: center;
img {
  height: 100%;
}
@media (max-width: 600px) {
  height:650px;
}
`


export default function Home (props){
  useEffect(() => {
    props.click('home');
    let div = document.getElementById('videoLoop2')
    if(div) {
      let innerDiv = div.getElementsByTagName('div');
      if(innerDiv && innerDiv[0]) {
        innerDiv[0].style.setProperty('display', 'none');
      }
     
    }

  }, [])

  return (
    <>
     <div>
    <BgImgContainer>
   
    <ImgDiv>

    {/* <img src="/images/vr_final_1.gif" layout='fill' objectFit='cover'></img> */}
    <VideoLooper id={'videoLoop2'} source='/images/vr_final_1.mp4' start={0} end={7.6} height="100%" objectPosition="50%" autoPlay />
    
    </ImgDiv>

</BgImgContainer>
</div>

<div id='rssBlock'>
<p class="cnnContents">

<span class="marqueeStyle">&nbsp;/// Cogitare Labs is creating Safe & Responsible Digital Beings /// Cogitare Labs is creating Safe & Responsible Digital Beings&nbsp;</span>
<span class="marqueeStyle2">&nbsp;/// Cogitare Labs is creating Safe & Responsible Digital Beings /// Cogitare Labs is creating Safe & Responsible Digital Beings</span>
</p>
</div>
</>
  )
        }
