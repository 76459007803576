import styled from 'styled-components';
export const HeaderDiv = styled.div`
color: white;
font-size: 20px;
margin-bottom: 1rem;
font-family: Helvetica;
text-decoration: underline;
text-underline-offset: 0.5rem;
line-height: 1.8;
`

export const HeaderDiv1 = styled.div`
color: white;
font-size: 18px;
margin-bottom: 1rem;
font-family: Helvetica;
`

export const DescrDiv = styled.div`
font-family: Helvetica;
font-size: 18px;
line-height: 1.5;
ol {
  padding-left: 0px;
}
`

export const InfoContainer = styled.div`
padding: 1rem;
// background-color: #1d1d1f;
`

export const FlexContainer = styled.div`
display: flex;
flex-wrap: wrap;
justify-content: center;
align-items: center;
`

export const OnelinerDiv = styled.div`
font-family: Helvetica;
font-size: 18px;
`