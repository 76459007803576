import React, { useState, useEffect } from 'react'
import { HeaderDiv1, DescrDiv } from '../styles/div';
export default function Career(props) {
    useEffect(() => {
        props.click('career');
      }, [])
    return (<div style={{textAlign: "left", overflowY: "scroll", padding: "2rem", marginTop: "4rem"}}>


    <div style={{padding: "1rem"}}>
    <DescrDiv>Please get in touch at <a style={{textDecoration: "underline"}} href="mailto:info@cogitarelabs.com?subject=Apply&body=Hi Team">info@cogitarelabs.com</a></DescrDiv>
    </div>

    <div style={{padding: "1rem"}}>
    <HeaderDiv1>Animators - 2D</HeaderDiv1>
    <DescrDiv>
        <ol>Likes to create funny cartoonistic animations using any tool of choice: Adobe Animate, Toon harmony, etc</ol>
    </DescrDiv>
    </div>

    <div style={{padding: "1rem"}}>
    <HeaderDiv1>Animators - Rive App</HeaderDiv1>
    <DescrDiv>
        <ol>please refer to https://rive.app/. </ol>
        <ol>2D Animators who had previous experience in Adobe Animate(previously flash), toon harmony  and are willing to learn Rive App Editor can also apply.</ol>
        <ol>Will be responsible for creating interactive animations in Rive App editor</ol>
    </DescrDiv>
    </div>

    <div style={{padding: "1rem"}}>
    <HeaderDiv1>Animatic Creators - 2D</HeaderDiv1>
    <DescrDiv>
        <ol>Likes to create funny detailed animatics which will help animators.</ol>
    </DescrDiv>
    </div>

    <div style={{padding: "1rem"}}>
    <HeaderDiv1>Graphics Designers</HeaderDiv1>
    <DescrDiv>
        <ol>Liked to create futuristic cool themes, designs. Are free to completely design out of their imagination based on given concepts</ol>
    </DescrDiv>
    </div>

    <div style={{padding: "1rem"}}>
    <HeaderDiv1>NFT Artists</HeaderDiv1>
    <DescrDiv>
        <ol>Develop variants of character, designs, etc. </ol>
    </DescrDiv>
    </div>

    <div style={{padding: "1rem"}}>
    <HeaderDiv1>Coders-MERN</HeaderDiv1>
    <DescrDiv>
        <ol>Who are willing to work on MERN stack. Freshers or intern are also welcome. </ol>
    </DescrDiv>
    </div>

    <div style={{padding: "1rem"}}>
    <HeaderDiv1>Coders-Flutter</HeaderDiv1>
    <DescrDiv>
        <ol>Who know how to develop mobile apps using Flutter. Freshers or intern are also welcome.</ol>
    </DescrDiv>
    </div>


   

</div>)
}